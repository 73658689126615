import * as React from "react";

import { styled } from "../../utils/theme";
import { Grid } from "../Grid";
import { T6, TM, TP } from "../Typography";

const Wrapper = styled.div<{ wide?: number; vertical: boolean }>`
	display: grid;
	grid-template-columns: ${({ vertical }) => (vertical ? "1fr" : "200px auto")};
	grid-column: ${({ wide }) => (wide ? `1/${wide + 1}` : "1")};
	width: 100%;
	padding: 0px 0px ${({ theme }) => theme.spacing.xxxs} 0px;
	border-bottom: ${({ theme }) => `${theme.widths.hairline} #d9d9d9 solid`};

	&:last-child {
		border-bottom: none;
	}
`;

const Description = styled.div<{ small?: boolean; vertical: boolean }>`
	padding-right: ${({ theme }) => theme.spacing.xxxs};

	${({ vertical, theme, small }) =>
		vertical
			? `
		padding-bottom: ${small ? theme.spacing.none : theme.spacing.xxxs};
		border-bottom: ${theme.widths.hairline} #d9d9d9 dashed;
		margin-bottom: ${theme.spacing.xxxs};
	`
			: ""};

	div {
		margin-bottom: ${({ theme }) => theme.spacing.quark};
	}
`;

const Content = styled.div`
	width: 100%;
`;

interface Props {
	title: string;
	description?: string;
	columns?: number;
	auto?: boolean;
	vertical?: boolean;
	wide?: number;
	small?: boolean;
}

export const Section = (props: React.PropsWithChildren<Props>) => {
	const { title, description, children, auto, columns, vertical, wide, small } = props;

	const titleInfo = small ? (
		<>
			<TP fontWeight="black">{title}</TP>
			{description ? <TM>{description}</TM> : null}
		</>
	) : (
		<>
			<T6 fontWeight="black">{title}</T6>
			{description ? <TM>{description}</TM> : null}
		</>
	);

	return (
		<Wrapper wide={wide} vertical={Boolean(vertical)}>
			<Description small={small} vertical={Boolean(vertical)}>
				{titleInfo}
			</Description>
			<Content>
				<Grid columns={columns ?? 1} auto={auto} gap="nano" verticalGap="xxxs" padding="none">
					{children}
				</Grid>
			</Content>
		</Wrapper>
	);
};
