import { IStyleRelativeProps } from ".";
import { styled } from "../../utils/theme";

export const Sicon = styled("i")<IStyleRelativeProps>`
	display: inline-block;
	font-style: normal;
	vertical-align: -0.185em;
	text-align: center;
	text-transform: none;
	line-height: 0;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	* {
		line-height: 1;
	}

	svg {
		width: 1em;
		height: 1em;
		fill: currentColor;
	}
`;

export const Sbutton = styled.button<IStyleRelativeProps>`
	display: inline-block;
	text-align: center;
	border-radius: 20px;
	margin: 4px;
	border: 0;
	outline: 0;
	cursor: pointer;
	position: relative;
	white-space: nowrap;

	${({ type }) => {
		const options = {
			default: `
				background-color: #ccc;
				box-shadow: 0 0 0 1px #ccc inset;
				color: #000;
			`,
			primary: `
				background-color: #4dbfd8;
				box-shadow: 0 0 0 1px #4dbfd8 inset;
				color: #fff;
			`,
			secondary: `
				background-color: #1b1c1d;
				box-shadow: 0 0 0 1px #1b1c1d inset;
				color: #fff;
			`,
			negative: `
				background-color: #b21e1e;
				box-shadow: 0 0 0 1px #b21e1e inset;
				color: #fff;
			`,
			positive: `
				background-color: #16ab39;
				box-shadow: 0 0 0 1px #16ab39 inset;
				color: #fff;
			`,
		};

		return options[type || "default"];
	}}

	${({ size }) => {
		const options = {
			default: `
				padding: 6px 18px;
				font-size: 14px;
			`,
			small: `
				padding: 4px 12px;
				font-size: 11px;
			`,
			large: `
				padding: 8px 24px;
				font-size: 20px;
			`,
		};

		return options[size || "default"];
	}}

	${({ buttonXls }) => {
		if (buttonXls) {
			return `
				padding-right: 48px;

				i {
					position: absolute;
					top: 50%;
					right: 6px;
					font-size: 22px;
					transform: translateY(-50%);
					border-left: 1px solid rgba(255, 255, 255, 0.4);
					padding: 0 3px;
					z-index: 2;

					svg {
						transition: transform ease 0.2s;
					}

					&:hover svg {
						transform: scale(1.2);
					}
				}
			`;
		}

		return "";
	}}

	
	${({ disabled }) => {
		if (disabled) {
			return `
				pointer-events: none;
				opacity: 0.6;
			`;
		}

		return "";
	}}

	 ${({ loading }) => {
		if (loading) {
			return `
				pointer-events: none;
				i{
					margin-left: 4px;
				}
			`;
		}

		return "";
	}}
`;

interface ISbuttonDropDown {
	active: boolean;
}
export const SbuttonDropDown = styled("div")<ISbuttonDropDown>`
	position: absolute;
	bottom: -30px;
	left: 4px;
	right: 4px;
	line-height: 1.8;
	opacity: 0;
	pointer-events: none;
	padding: 2px;
	font-size: 13px;
	transform: translateY(-30px) translateY(0) scale(0.6);
	transform-origin: top right;
	text-align: center;
	border-radius: 8px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.1);
	background: #fff;
	transition: all ease 0.2s;
	border-radius: 20px;

	${({ active }) => {
		if (active) {
			return `
				opacity: 1;
				pointer-events: all;
				transform: translateY(0) scale(1);
				cursor: pointer;
			`;
		}

		return "";
	}}

	&:before {
		content: "";
		position: absolute;
		top: -10px;
		left: 0;
		right: 0;
		height: 10px;
	}

	&:hover {
		background: #f6f6f6;
	}
`;

export const ScontainerButtons = styled("div")`
	position: relative;
	display: inline-block;
`;

export const SfileInput = styled("input")`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	cursor: pointer;
	overflow: hidden;
`;

function applyStyleIfExistProp<IProps>(testProp: keyof IProps) {
	return (style: TemplateStringsArray) => (props: IProps) => {
		if (props !== undefined && props[testProp]) {
			return style;
		}

		return "";
	};
}

interface Anny {
	[key: string]: any;
}
export function theme<Props extends Anny, Pkey extends keyof Props>(
	propName: Pkey,
	theme: Record<Props[Pkey], string>,
): (props: Props) => string {
	return (props: Props) => {
		if (props[propName] !== undefined && theme[props[propName]] !== undefined) {
			return theme[props[propName]];
		}

		return "";
	};
}
