import React, { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";

import { theme } from "../../utils/theme";
import { Container, LabelToUpload } from "./styles";

interface IImageUpload {
	urlImage?: string;
	size?: number;
	onChange: (file: File) => void;
}

export function ImageUpload({ urlImage, size, onChange }: IImageUpload) {
	const [imageUrl, setImageUrl] = useState(urlImage);
	const imageRef = useRef<HTMLImageElement | null>(null);

	useEffect(() => {
		if (urlImage) {
			setImageUrl(urlImage);
		}
	}, [urlImage]);

	function onChangeImage(file: FileList) {
		const reader = new FileReader();

		reader.onload = function (e) {
			setImageUrl(e.target!.result as string);
		};

		reader.readAsDataURL(file[0]);
	}

	return (
		<Container size={size}>
			<label htmlFor="image">
				{imageUrl ? (
					<img src={imageUrl} alt="" ref={imageRef} />
				) : (
					<LabelToUpload size={size}>
						<FaPlus size={22} color={theme.colors.others.dark.medium} />

						<p>Adicionar foto</p>
					</LabelToUpload>
				)}

				<input
					type="file"
					id="image"
					accept="image/*"
					onChange={event => {
						onChangeImage(event.target.files!);
						onChange(event.target.files![0]);
					}}
				/>
			</label>
		</Container>
	);
}
