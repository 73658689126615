import React from "react";

import { Button as BaseButton, IButtonProps } from "./styles";

export function Button({
	children,
	backgroundColor = "green",
	textColor = "",
	hoverColor = "",
	loadingColor = "#222222",
	...props
}: IButtonProps) {
	return (
		<BaseButton
			backgroundColor={backgroundColor}
			textColor={textColor}
			hoverColor={hoverColor}
			loadingColor={loadingColor}
			{...props}
		>
			{children}
		</BaseButton>
	);
}
