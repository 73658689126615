import React from "react";

// new form , single step

/*
 * validation
 * form in
 * form out
 * initialValues
 * onSubmit
 * wrapper
 */

export type FieldMessage<FormFields> = Partial<Record<keyof FormFields, string | undefined>>;
export type FieldTouched<FormFields> = Partial<Record<keyof FormFields, boolean>>;

export type SetValue<FormFields> = (
	fieldName: keyof FormFields,
	value: FormFields[keyof FormFields],
) => void;
export type SetMultipleValues<FormFields> = (newValues: Partial<FormFields>) => void;
export type SetTouched<FormFields> = (fieldName: keyof FormFields) => void;

export interface validatorReturn<FormFields> {
	errors: FieldMessage<FormFields>;
	warnings: FieldMessage<FormFields>;
}

export interface FromRendererProps<FormFields> {
	values: FormFields;
	errors: FieldMessage<FormFields>;
	warnings: FieldMessage<FormFields>;
	touched: FieldTouched<FormFields>;
	setValue: SetValue<FormFields>;
	setMultipleValues: SetMultipleValues<FormFields>;
	setTouched: SetTouched<FormFields>;
	submit: () => void;
	reset: () => void;
}

interface Props<ValuesIn, ValuesOut, FormFields> {
	validation: (fields: FormFields) => validatorReturn<FormFields>;
	formIn: (valueIn: ValuesIn) => FormFields;
	formOut: (FormFields: FormFields) => ValuesOut;
	initialValues: ValuesIn;
	onSubmit: (values: ValuesOut) => void;
	formRender: (props: FromRendererProps<FormFields>) => JSX.Element;
}

interface State<FormFields> {
	values: FormFields;
	errors: FieldMessage<FormFields>;
	warnings: FieldMessage<FormFields>;
	touched: FieldTouched<FormFields>;
}

export class Form<ValuesIn, ValuesOut, FormFields> extends React.Component<
	Props<ValuesIn, ValuesOut, FormFields>,
	State<FormFields>
> {
	public state: State<FormFields> = {
		values: this.props.formIn(this.props.initialValues),
		errors: {},
		warnings: {},
		touched: {},
	};

	private touch = (fieldName: keyof FormFields) => {
		const { state } = this;

		this.setState({
			...state,
			touched: {
				...state.touched,
				[fieldName]: true,
			},
		});
	};

	private hasErrors = (errors: FieldMessage<FormFields>) => {
		return Object.keys(errors).reduce(
			(previous, current) => Boolean(errors[current as keyof FormFields]) ?? previous,
			false,
		);
	};

	private resetValues = () => {
		this.setState({
			values: this.props.formIn(this.props.initialValues),
			errors: {},
			warnings: {},
			touched: {},
		});
	};

	private requestSubmit = () => {
		const { state, props } = this;

		const { errors, warnings } = props.validation(state.values);

		if (this.hasErrors(errors)) {
			// mark all fields as touched
			const keysToObjMapTrue = (obj: Partial<FormFields>) =>
				Object.keys(obj).reduce((acc, objKey) => ({ ...acc, [objKey]: true }), {});
			const newTouched = { ...state.touched, ...keysToObjMapTrue(state.values) };

			// update state with errors, warnings and all fields as touched
			const newState: State<FormFields> = {
				...state,
				touched: newTouched,
				errors,
				warnings,
			};

			this.setState(newState);
		} else {
			props.onSubmit(props.formOut(state.values));
		}
	};

	private changeMultipleFields = (partialValue: Partial<FormFields>) => {
		const { state, props } = this;

		const keysToObjMapTrue = (obj: Partial<FormFields>) =>
			Object.keys(obj).reduce((acc, objKey) => ({ ...acc, [objKey]: true }), {});

		const newValues = { ...state.values, ...partialValue };
		const newTouched = { ...state.touched, ...keysToObjMapTrue(partialValue) };

		const { errors, warnings } = props.validation(newValues);

		const newState: State<FormFields> = {
			...state,
			touched: newTouched,
			values: newValues,
			errors,
			warnings,
		};

		this.setState(newState);
	};

	private changeValue = (fieldName: keyof FormFields, value: FormFields[keyof FormFields]) => {
		const { state, props } = this;

		const newValues = { ...state.values, [fieldName]: value };
		const newTouched = { ...state.touched, [fieldName]: true };

		const { errors, warnings } = props.validation(newValues);

		const newState: State<FormFields> = {
			...state,
			touched: newTouched,
			values: newValues,
			errors,
			warnings,
		};

		this.setState(newState);
	};

	public render() {
		return this.props.formRender({
			// data
			errors: this.state.errors,
			touched: this.state.touched,
			values: this.state.values,
			warnings: this.state.warnings,

			// actions
			setValue: this.changeValue,
			setTouched: this.touch,
			submit: this.requestSubmit,
			reset: this.resetValues,
			setMultipleValues: this.changeMultipleFields,
		});
	}
}
