import { keyframes } from "styled-components";

import { styled } from "../../utils/theme";

interface ILoadingCircleProps {
	size: number;
	color: string;
}

export const LoadingWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const RotateAnimation = keyframes`  
	0% {
    	transform: rotate(0deg);
  	}
  	100% {
    	transform: rotate(360deg);
  	}
`;

export const LoadingCircle = styled.span<ILoadingCircleProps>`
	width: ${({ size }) => `${size}px`};
	height: ${({ size }) => `${size}px`};
	border: transparent solid 3px;
	border-bottom-color: ${({ color }) => color};
	border-radius: 50%;
	display: inline-block;
	animation: ${RotateAnimation} 1s linear infinite;
`;
