import RCDialog from "rc-dialog";
import * as React from "react";

import { Grid, TP } from "../..";
import { styled } from "../../utils/theme";
import { Button, IButtonProps } from "../Button";
import { zigProviderAnchorId } from "../ZigProvider";

interface ButtonOptions extends Omit<IButtonProps, "children"> {
	content?: React.ReactNode;
}

interface Props {
	title?: string;
	visible?: boolean;
	onClose?: () => void;
	diasableClickOutsideToClose?: boolean;
	width?: number;
	rightButtons?: ButtonOptions[];
	centerButtons?: ButtonOptions[];
	leftButtons?: ButtonOptions[];
}

const Header = styled.div`
	/* height: 40px; */
	background: #3fbad4;
	display: flex;
	justify-content: space-between;
	padding: 8px 14px 8px 24px;
	border-radius: 16px 16px 0px 0px;
	align-items: center;

	div {
		color: white;
	}
`;

const Content = styled.div`
	padding: 20px 24px;
`;

const Footer = styled.div`
	border-top: 1px solid #f2f2f2;
	/* background-color: wheat; */
	/* border: 1px solid black; */
	padding: 8px 24px;
	border-radius: 0px 0px 16px 16px;

	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CloseButtonWrapper = styled.div`
	height: 30px;
	width: 30px;
	cursor: pointer;

	svg {
		cursor: pointer;
		fill: white;
		height: 10px;
		width: 10px;
		padding: 0px;
		margin: 10px;
		line-height: 10px;
	}
`;

const CloseIcon = (props: { onClick?: () => void }) => (
	<svg
		onClick={props.onClick}
		height="10px"
		width="10px"
		viewBox="0 0 365.696 365.696"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
	</svg>
);

export function Dialog(props: React.PropsWithChildren<Props>) {
	const renderButtons = (btnOptions: ButtonOptions[] | undefined) => (
		<Grid columns={btnOptions ? btnOptions.length : 0} gap="nano" padding="none">
			{btnOptions
				? btnOptions.map((btnOptions, i) => (
						<Button key={`btn-${i}`} {...btnOptions}>
							{btnOptions.content}
						</Button>
				  ))
				: null}
		</Grid>
	);

	return (
		<RCDialog
			visible={props.visible}
			closable={false}
			getContainer={`#${zigProviderAnchorId}`}
			maskClosable={
				props.diasableClickOutsideToClose === undefined ? true : !props.diasableClickOutsideToClose
			}
			destroyOnClose
			onClose={props.onClose}
			width={props.width || 600}
		>
			<Header>
				<TP fontWeight="bold">{props.title || null}</TP>
				<CloseButtonWrapper onClick={props.onClose}>
					<CloseIcon />
				</CloseButtonWrapper>
			</Header>
			<Content>{props.children}</Content>
			<Footer>
				{renderButtons(props.leftButtons)}
				{renderButtons(props.centerButtons)}
				{renderButtons(props.rightButtons)}
			</Footer>
		</RCDialog>
	);
}
