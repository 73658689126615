import { ColorBlock, Container } from "./styles";
import React, { useState } from "react";

import { useEffect } from "react";
import { borderRadiusType, borderWidthsType, spacingSize, theme } from "../../utils/theme";

interface ColorPickerProps {
	value?: string;
	onChange?: (value: string | undefined) => void;
	direction?: "row" | "column";
	colors: string[];
	blockWidth?: number;
	blockHeight?: number;
	borderRadius?: borderRadiusType;
	spacing?: spacingSize;
	activeBorderWidth?: borderWidthsType;
	activeBorderColor?: string;
}

export function ColorPicker({
	value,
	onChange,
	direction = "row",
	colors,
	blockWidth = 20,
	blockHeight = 20,
	borderRadius = "none",
	spacing = "none",
	activeBorderWidth = "thin",
	activeBorderColor = theme.colors.brand.primary.medium,
}: ColorPickerProps) {
	const [selectedColor, setSelectedColor] = useState<string | undefined>(value);

	useEffect(() => {
		if (onChange) {
			onChange(selectedColor);
		}
	}, [selectedColor]);

	return (
		<Container direction={direction} spacing={spacing}>
			{colors.map(color => (
				<ColorBlock
					key={color}
					background={color}
					blockWidth={blockWidth}
					blockHeight={blockHeight}
					borderRadius={borderRadius}
					isActive={color === selectedColor}
					onClick={() => setSelectedColor(color === selectedColor ? undefined : color)}
					activeBorderWidth={activeBorderWidth}
					activeBorderColor={activeBorderColor}
				/>
			))}
		</Container>
	);
}
