import React, { useRef, useState } from "react";

import { treatStringForSearch } from "../../helpers/treatStringForSearch";
import { useOutsideClick } from "../../helpers/useOutsideClick";
import { IOptions } from "../../utils/types";
import { ListOptions } from "../ListOptions";
import { Input, Props as InputBasicProps } from "./basic";
import { AutoCompleteContainer } from "./styles";

interface IProps extends InputBasicProps {
	options: IOptions[];
}

export function AutoCompleteInput({ options, onChange, ...props }: IProps) {
	const [showList, setShowList] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const ref = useRef<HTMLDivElement>(null);

	useOutsideClick(ref, () => {
		setShowList(false);
	});

	const optionsFiltered = options.filter(option =>
		treatStringForSearch(option.label).includes(treatStringForSearch(searchValue)),
	);

	return (
		<AutoCompleteContainer ref={ref}>
			<Input
				onClick={() => setShowList(currValue => !currValue)}
				onChange={(value, event) => {
					setSearchValue(value);
					onChange && onChange(value, event);
				}}
				value={searchValue}
				{...props}
			/>

			<ListOptions
				show={showList}
				handleOptions={optionsFiltered}
				onClose={() => setShowList(false)}
				onChange={onChange as any}
			/>
		</AutoCompleteContainer>
	);
}
