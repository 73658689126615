import * as React from "react";

import { T4 } from "../Typography";
import { Actions, Content, Header, LayoutWrapper, Title } from "./defaultLayout.styles";

interface Props {
	title: React.ReactNode;
	actions?: React.ReactNode;
}

/*
 * |----------------------|
 * | title |      actions |
 * |----------------------|
 * |                      |
 * |      children        |
 * |                      |
 * |----------------------|
 */

export function DefaultLayout(props: React.PropsWithChildren<Props>) {
	const isTitleString = typeof props.title === "string";

	return (
		<LayoutWrapper>
			<Header>
				<Title>{isTitleString ? <T4 fontWeight="bold">{props.title}</T4> : props.title}</Title>
				<Actions>{props.actions ?? null}</Actions>
			</Header>
			<Content>{props.children}</Content>
		</LayoutWrapper>
	);
}
