import * as React from "react";

import { Hider } from "../Hider";
import { SFieldImage, SImageBox } from "./style";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
	required?: boolean;
	className?: string;
	defaultImage?: string;
	column?: boolean;
	style?: React.CSSProperties;
	onChange?: (image: Buffer) => void;
}

interface IState {
	image: string | undefined;
}

export class InputImage extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = { image: props.defaultImage ? props.defaultImage : "" };
	}

	public handleChange = (eventChange: React.ChangeEvent<HTMLInputElement>) => {
		if (eventChange.currentTarget.files) {
			const file = eventChange.currentTarget.files[0];
			const reader = new FileReader();

			reader.onload = (evt: any) => {
				const base64 = evt.currentTarget.result;
				const buffer = new Buffer(base64.replace(/data\:image\/(.*)\;base64\,/, ""), "base64");

				this.setState({ image: base64 });
				this.props.onChange && this.props.onChange(buffer);
			};

			reader.readAsDataURL(file);
		}
	};

	render() {
		const { column, className, required, style, ...rest } = this.props;

		return (
			<SFieldImage className={`zigImage ${className}`} style={style}>
				<SImageBox style={{ backgroundImage: `url(${this.state.image})` }}>
					<input
						{...rest}
						onChange={eventChange => {
							this.handleChange(eventChange);
						}}
						accept="image/png, image/jpeg"
						type="file"
					/>
					<Hider hide={this.state.image !== ""}>
						<span>+</span>
					</Hider>
				</SImageBox>
			</SFieldImage>
		);
	}
}
