import * as React from "react";

import { Input, Props as BasicInputProps } from "./basic";
import { Addon, Group } from "./styles";

export interface Props extends Omit<BasicInputProps, "prefix" | "suffix"> {
	prefix?: React.ReactNode;
	suffix?: React.ReactNode;
}

export const CustomInput = (props: Props) => {
	const { prefix, suffix, ...restOfProps } = props;

	return (
		<Group>
			<Input {...restOfProps} />
			<Addon
				error={props.error}
				warning={props.warning}
				onClick={props.disabled ? undefined : props.onClick}
			>
				{prefix}
			</Addon>
			<Addon
				error={props.error}
				warning={props.warning}
				onClick={props.disabled ? undefined : props.onClick}
			>
				{suffix}
			</Addon>
		</Group>
	);
};
