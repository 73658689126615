import * as React from "react";

import { styled } from "../../utils/theme";
import { Button } from "../Button";
import { Grid } from "../Grid";
import { T4, TM } from "../Typography";

const FormWrapper = styled.div<{ noPadding?: boolean }>`
	width: 100%;
	padding: ${({ theme, noPadding }) => (noPadding ? theme.spacing.none : theme.spacing.xs)};
	max-width: 1200px;
	box-sizing: border-box;
`;

const Header = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
	padding-bottom: ${({ theme }) => theme.spacing.nano};
	border-bottom: ${({ theme }) => `${theme.widths.thin} ${theme.colors.others.light.medium} solid`};
	margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const Footer = styled.div`
	border-top: ${({ theme }) => `${theme.widths.thin} ${theme.colors.others.light.medium} solid`};
	padding-top: ${({ theme }) => theme.spacing.nano};
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const Content = styled.div<{ noPadding?: boolean }>`
	padding: ${({ noPadding }) => (noPadding ? "0px" : "8px 0px")};
	width: 100%;
`;

interface Props {
	title: string;
	chanchangesHaveBeenMade?: boolean;
	onSubmit?: () => void;
	onCancel?: () => void;
	hideSubmit?: boolean;
	hideCancel?: boolean;
	noPadding?: boolean;
	cancelText?: string;
	submitText?: string;
	gridColumns?: number;
	gridAuto?: boolean;
	loadingSubmit?: boolean;
}

export const FormLayout = (props: React.PropsWithChildren<Props>) => {
	const {
		title,
		chanchangesHaveBeenMade,
		children,
		onCancel,
		cancelText,
		onSubmit,
		submitText,
		gridColumns,
		gridAuto,
		hideCancel,
		hideSubmit,
		loadingSubmit,
		noPadding,
	} = props;

	return (
		<FormWrapper noPadding={noPadding}>
			<Header>
				<T4 fontWeight="black">{title}</T4>
				<TM>{chanchangesHaveBeenMade ? "Alterações foram feitas" : ""}</TM>
			</Header>
			<Content>
				<Grid
					columns={gridColumns ?? 1}
					auto={gridAuto}
					gap="nano"
					verticalGap="xxxs"
					padding="none"
				>
					{children}
				</Grid>
			</Content>
			<Footer>
				{hideCancel ? (
					<div />
				) : (
					<Button typeButton="ghost" onClick={onCancel}>
						{cancelText ?? "Cancelar"}
					</Button>
				)}
				{hideSubmit ? (
					<div />
				) : (
					<Button onClick={onSubmit} isLoading={loadingSubmit}>
						{submitText ?? "Salvar"}
					</Button>
				)}
			</Footer>
		</FormWrapper>
	);
};

export const FormGuts = (
	props: React.PropsWithChildren<Pick<Props, "gridColumns" | "gridAuto">>,
) => {
	const { children, gridColumns, gridAuto } = props;

	return (
		<Content noPadding>
			<Grid columns={gridColumns || 1} auto={gridAuto} gap="nano" verticalGap="xxxs" padding="none">
				{children}
			</Grid>
		</Content>
	);
};
