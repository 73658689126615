import * as React from "react";

interface IProps {
	hide: boolean;
	keepMounted?: boolean;
	children: React.ReactNode;
}

export class Hider extends React.Component<IProps> {
	public static defaultProps = {
		keepMounted: false,
	};

	public render() {
		const { hide, keepMounted, children } = this.props;

		if (keepMounted) {
			return <div style={hide ? { display: "none" } : undefined}>{children}</div>;
		}

		return hide ? null : children;
	}
}
