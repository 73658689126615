import React from "react";

import { methods } from "./methods";
import { StepperProps, StepperState } from "./types";

export class Stepper<StepKeys extends string, ValueType extends object> extends React.Component<
	StepperProps<StepKeys, ValueType>,
	StepperState<ValueType>
> {
	public state: StepperState<ValueType> = {
		activeStepIndex: 0,
		values: this.props.initialValues,
		errors: {},
		warnings: {},
		touched: {},
		stepIndexHistory: [0],
		isFirstStep: true,
		isLastStep: false,
		...methods.calculateSkipability(this, this.props.initialValues),
	};

	public render() {
		const { state, props } = this;

		const ActiveStepElement = props.steps[state.activeStepIndex].render({
			values: state.values,
			errors: state.errors,
			warnings: state.warnings,
			touched: state.touched,
			setMultipleValues: newValues => methods.setMultipleValues(this, newValues),
			setValue: (fieldName, value) => methods.setValue(this, fieldName, value),
			setTouched: fieldName => methods.setTouched(this, fieldName),
			resetAll: () => methods.resetAll(this),
			jump: s => methods.jump(this, s),
			goForward: () => methods.goForward(this),
			goBackwards: () => methods.goBackwards(this),
		});

		return this.props.wrapper({
			children: ActiveStepElement,
			resetAll: () => methods.resetAll(this),
			goForward: () => methods.goForward(this),
			goBackwards: () => methods.goBackwards(this),
			jump: s => methods.jump(this, s),
			currentStepIndex: state.activeStepIndex,
			steps: props.steps,
			isFirstStep: state.isFirstStep,
			isLastStep: state.isLastStep,
			stepsToSkip: state.stepsToSkip,
		});
	}
}
