import * as React from "react";

import { Button, IButtonProps } from ".";
import { ScontainerButtons, SbuttonDropDown, SfileInput, Sicon } from "./style";

interface IXlsButtonProps extends IButtonProps {
	onChange: (fileUrl: string) => void;
	className?: string;
	download: () => void;
	children: React.ReactNode;
}

interface IState {
	showDropDown: boolean;
}

export class ButtonImport extends React.Component<IXlsButtonProps, IState> {
	public static defaultProps = {
		type: "default",
		size: "default",
	};

	constructor(props: IXlsButtonProps) {
		super(props);
		this.state = {
			showDropDown: false,
		};
	}

	private handleChange = (eventChange: React.ChangeEvent<HTMLInputElement>) => {
		if (eventChange.currentTarget.files) {
			const file = eventChange.currentTarget.files[0];
			const reader = new FileReader();

			reader.onload = (evt: any) => {
				this.props.onChange && this.props.onChange(evt.currentTarget.result);
			};

			reader.readAsDataURL(file);
		}
	};

	private setDropDown(val: boolean) {
		this.setState({ showDropDown: val });
	}

	private click(event: React.MouseEvent<HTMLElement>, val: boolean) {
		this.setState({ showDropDown: val });
		event.stopPropagation();
	}

	public render() {
		const { children, download, ...rest } = this.props;

		return (
			<ScontainerButtons onMouseLeave={() => this.setDropDown(false)}>
				<SbuttonDropDown
					active={this.state.showDropDown}
					onClick={() => {
						download();
					}}
				>
					Baixar planilha de exemplo
				</SbuttonDropDown>
				<Button buttonXls {...rest}>
					{children}{" "}
					<Sicon
						onClick={evt => {
							this.click(evt, true);
						}}
					>
						<svg viewBox="0 0 512 512">
							<path d="M296 136c0-22.002-17.998-40-40-40s-40 17.998-40 40 17.998 40 40 40 40-17.998 40-40zm0 240c0-22.002-17.998-40-40-40s-40 17.998-40 40 17.998 40 40 40 40-17.998 40-40zm0-120c0-22.002-17.998-40-40-40s-40 17.998-40 40 17.998 40 40 40 40-17.998 40-40z" />
						</svg>
					</Sicon>
					<SfileInput onChange={this.handleChange} type="file" accept=".csv, .xls, .xlsx" />
				</Button>
			</ScontainerButtons>
		);
	}
}
