import { useState } from "react";

export function useDisclosure() {
	const [isOpen, setIsOpen] = useState(false);

	function handleOpen() {
		setIsOpen(true);
	}

	function handleClose() {
		setIsOpen(false);
	}

	return { isOpen, handleOpen, handleClose };
}
