import { onlyNumbers } from "./onlyNumbers";

export function universalNumberMask(template: string, value: string) {
	const markerIndexes: number[] = template
		.split("")
		.reduce(
			(acc: number[], cur: string, index: number) => (cur === "X" ? [...acc, index] : acc),
			[],
		);

	const numbersArray = onlyNumbers(value).split("").slice(0, markerIndexes.length);
	let outputValue = template.split("");

	numbersArray.forEach((n, i) => {
		outputValue[markerIndexes[i]] = n;
	});

	outputValue = outputValue.slice(0, markerIndexes[numbersArray.length - 1] + 1);

	return outputValue.join("");
}
