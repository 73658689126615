import React from "react";

import { onlyNumbers, universalNumberMask } from "../../..";
import { Mask } from "../../../components/Inputs/masked";
import { CustomInput, CustomInputProps } from "./custom";

export const cpfMask: Mask = {
	maskIn: (v: string) => universalNumberMask("XXX.XXX.XXX-XX", v),
	maskOut: (v: string) => onlyNumbers(v).slice(0, 11),
};

export function CPFInput(props: CustomInputProps) {
	const { value, onChange, onBlur, placeholder, ...otherProps } = props;

	const inputValue = cpfMask.maskIn(value ?? "");
	const inputOnChange = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onChange && onChange(cpfMask.maskOut(v), event);
	const inputOnBlur = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onBlur && onBlur(cpfMask.maskOut(v), event);

	return (
		<CustomInput
			prefix="CPF"
			value={inputValue}
			onBlur={inputOnBlur}
			onChange={inputOnChange}
			type="tel"
			placeholder={placeholder ?? "000.000.000-00"}
			{...otherProps}
		/>
	);
}
