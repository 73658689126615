import * as React from "react";

import { numberToString } from "../../../helpers/numberToString";
import { stringToNumber } from "../../../helpers/stringToNumber";
import { CustomInput, CustomInputProps } from "./custom";

interface Props extends Omit<CustomInputProps, "onChange" | "onBlur" | "value"> {
	onChange?: (value: number | null, event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (value: number | null, event: React.ChangeEvent<HTMLInputElement>) => void;
	value?: number | null;
	formatter?: (value: any) => string;
}

export const NumberInput = (props: Props) => {
	const { prefix, suffix, onBlur, onChange, value, formatter, ...restOfProps } = props;

	const inputValue = numberToString(value);

	const inputOnBlur = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onBlur && onBlur(stringToNumber(v), event);
	const inputOnChange = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onChange && onChange(stringToNumber(v), event);

	return (
		<CustomInput
			type="tel"
			prefix={prefix}
			suffix={suffix}
			value={formatter ? formatter(inputValue) : inputValue}
			onBlur={inputOnBlur}
			onChange={inputOnChange}
			{...restOfProps}
		/>
	);
};
