import { Input as ThemeInput } from "../../../components/Inputs/basic";
import { Addon as ThemeAddon } from "../../../components/Inputs/styles";
import { Textarea } from "../../../components/Inputs/textarea";
import { styled } from "../../../utils/theme";

interface ITextareaStyle {
	noBorder?: boolean;
}

export const Input = styled(ThemeInput)`
	${({ theme }) => theme.font.ubuntu};

	border-radius: 10px;

	&::placeholder {
		font-size: 14px;
	}

	${({ error, theme }) => {
		if (error) {
			return `
			border-color: ${theme.colors.b2c.pink};
			color: ${theme.colors.b2c.pink};
			`;
		}

		return "";
	}}
`;

export const Addon = styled(ThemeAddon)`
	${({ theme }) => theme.font.ubuntu}

	border-radius: 10px;

	${({ error, theme }) => {
		if (error) {
			return `
			border-color: ${theme.colors.b2c.pink};
			color: ${theme.colors.b2c.pink};
      `;
		}

		return "";
	}}
`;

export const ErrorMessage = styled.div`
	margin-left: 4px;
	margin-top: 4px;
	div {
		color: ${({ theme }) => theme.colors.b2c.pink};
	}
`;

export const RequiredSign = styled.span`
	color: ${({ theme }) => theme.colors.b2c.pink};
`;

export const TextareaB2CStyle = styled(Textarea)<ITextareaStyle>`
	${({ noBorder }) => {
		if (noBorder) {
			return `
				border: 0;
      `;
		}

		return "";
	}}

	${({ error, theme }) => {
		if (error) {
			return `
				border-color: ${theme.colors.b2c.pink};
      `;
		}

		return "";
	}}
`;
