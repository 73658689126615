import { Stepper } from ".";
import { Errors, Touched, StepperState } from "./types";

type That<StepKeys extends string, ValueType extends object> = Stepper<StepKeys, ValueType>;

function touch<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
	fieldName: keyof ValueType,
) {
	const { state } = that;

	that.setState({
		...state,
		touched: {
			...state.touched,
			[fieldName]: true,
		},
	});
}

function changeMultipleValues<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
	partialValue: Partial<ValueType>,
) {
	const { state, props } = that;
	const keysToObjMapTrue = (obj: Partial<ValueType>) =>
		Object.keys(obj).reduce(
			(acc, objKey) => ({
				...acc,
				[objKey]: true,
			}),
			{},
		);
	const newValues = {
		...state.values,
		...partialValue,
	};
	const newTouched = {
		...state.touched,
		...keysToObjMapTrue(partialValue),
	};
	const newErrorsAndWarnings = props.validator(
		newValues,
		that.props.steps[state.activeStepIndex].key,
	);
	const skipabilityInfo = calculateSkipability(that, newValues);
	const isLastStep = state.activeStepIndex >= skipabilityInfo.lastIndexNotSkipable;
	const newState: StepperState<ValueType> = {
		...state,
		touched: newTouched,
		values: newValues,
		errors: newErrorsAndWarnings.errors,
		warnings: newErrorsAndWarnings.warnings,
		...skipabilityInfo,
		isLastStep,
	};

	that.setState(newState);
}

function changeValue<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
	fieldName: keyof ValueType,
	value: any,
) {
	const { state, props } = that;

	const newValues = {
		...state.values,
		[fieldName]: value,
	};
	const newTouched = {
		...state.touched,
		[fieldName]: true,
	};
	const newErrorsAndWarnings = props.validator(
		newValues,
		that.props.steps[state.activeStepIndex].key,
	);
	const skipabilityInfo = calculateSkipability(that, newValues);
	const isLastStep = state.activeStepIndex >= skipabilityInfo.lastIndexNotSkipable;

	const newState: StepperState<ValueType> = {
		...state,
		touched: newTouched,
		values: newValues,
		errors: newErrorsAndWarnings.errors,
		warnings: newErrorsAndWarnings.warnings,
		...skipabilityInfo,
		isLastStep,
	};

	that.setState(newState);
}

function resetValues<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
) {
	that.setState({
		activeStepIndex: 0,
		values: that.props.initialValues,
		errors: {},
		warnings: {},
		touched: {},
		stepIndexHistory: [0],
		isFirstStep: true,
		isLastStep: false,
		...calculateSkipability(that, that.props.initialValues),
	});
}

function hasErrors<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
	errors: Errors<ValueType>,
) {
	return Object.keys(errors).reduce(
		(previous, current) => Boolean(errors[current as keyof ValueType]) ?? previous,
		false,
	);
}

function setTouchedBasedOnErrors<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
	errors: Errors<ValueType>,
): Touched<ValueType> {
	const touched: Touched<ValueType> = {};

	return Object.keys(errors).reduce(
		(previous, current) => ({
			...previous,
			[current]: Boolean(errors[current as keyof ValueType]),
		}),
		touched,
	);
}

function findNextStepIndex<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
): number | null {
	const { activeStepIndex, lastIndexNotSkipable, stepsToSkip } = that.state;

	if (activeStepIndex === lastIndexNotSkipable) {
		return null;
	} // last step

	let nextIndex: number | null = null;

	for (let i = activeStepIndex + 1; i <= lastIndexNotSkipable; i++) {
		if (!stepsToSkip[i]) {
			nextIndex = i;
			break;
		}
	}

	return nextIndex;
}

function calculateSkipability<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
	formValues: ValueType,
): Pick<StepperState<ValueType>, "stepsToSkip" | "lastIndexNotSkipable"> {
	let newLastNonSkipableStepIndex = 0;
	const newSkipArray = that.props.steps.map((step, index) => {
		const skipable = step.shouldSkip ? step.shouldSkip(formValues) : false;

		if (!skipable) {
			newLastNonSkipableStepIndex = index;
		}

		return skipable;
	});

	return {
		stepsToSkip: newSkipArray,
		lastIndexNotSkipable: newLastNonSkipableStepIndex,
	};
}

function jump<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
	stepIndex: number,
) {
	const { state, props } = that;

	if (state.stepsToSkip[stepIndex]) {
		return;
	} // don't jump to skiped steps

	const { errors, warnings } = that.props.validator(
		state.values,
		that.props.steps[state.activeStepIndex].key,
	);
	const touched = { ...state.touched, ...setTouchedBasedOnErrors(that, errors) };

	if (hasErrors(that, errors)) {
		// cannont jump if current step has unsolved errors
		that.setState({
			...state,
			errors,
			warnings,
			touched,
		});
		return;
	} else if (state.stepIndexHistory.includes(stepIndex)) {
		// jumping backward

		/*
		 * search history for requested step and create a new history form the begging to the new index
		 * this avoid keeping a history list that is not crescent, if the list is not crecent you can
		 * click on the back button and go forward, which is very confusing.
		 */
		const newHistory = state.stepIndexHistory.slice(
			0,
			state.stepIndexHistory.findIndex((n, i) => n === stepIndex && i !== 0),
		);

		that.setState({
			...state,
			isLastStep: false, // you will never get to the last step if you jump backwards
			isFirstStep: stepIndex === 0, // always begin with step 0, non-skipable
			stepIndexHistory: newHistory,
			activeStepIndex: stepIndex,
		});
	} else if (props.canJumpForwardsFreely) {
		// jumping forward
		that.setState({
			...state,
			isLastStep: state.lastIndexNotSkipable === stepIndex,
			isFirstStep: false, // you will never get to the first step if you jump forwards
			stepIndexHistory: [...state.stepIndexHistory, state.activeStepIndex],
			activeStepIndex: stepIndex,
		});
	}
}

function forward<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
) {
	const { state, props } = that;
	const nextStepIndex = findNextStepIndex(that);

	const { errors, warnings } = that.props.validator(
		state.values,
		that.props.steps[state.activeStepIndex].key,
	);
	const touched = { ...state.touched, ...setTouchedBasedOnErrors(that, errors) };

	if (hasErrors(that, errors)) {
		that.setState({
			...state,
			errors,
			warnings,
			touched,
		});
		return;
	} else if (nextStepIndex === null) {
		// lastStep
		props.onSubmit(state.values);
	} else {
		// const isNextStepTheLast = !props.steps[nextStep].nextStep; // if there is no next step

		that.setState({
			...state,
			isLastStep: state.lastIndexNotSkipable === nextStepIndex, // redo
			isFirstStep: false, // you will never get to the first step if you walk forwards
			stepIndexHistory: [...state.stepIndexHistory, state.activeStepIndex],
			activeStepIndex: nextStepIndex,
		});
	}
}

function backwards<StepKeys extends string, ValueType extends object>(
	that: That<StepKeys, ValueType>,
) {
	const { state } = that;

	if (state.stepIndexHistory.length < 2) {
		return;
	} // do nothing

	const previousStep = state.stepIndexHistory.slice(-1)[0]; // get last item
	const isFirstStep = state.stepIndexHistory.slice(0, -1).length === 1;

	that.setState({
		...state,
		isFirstStep,
		isLastStep: false, // you will never get to the last step if you walk backwards
		stepIndexHistory: state.stepIndexHistory.slice(0, -1), // everything but the last item
		activeStepIndex: previousStep,
	});
}

// / EXPOSED METHODS
export const methods = {
	resetAll: resetValues,
	goForward: forward,
	goBackwards: backwards,
	jump,
	setTouched: touch,
	setValue: changeValue,
	setMultipleValues: changeMultipleValues,
	calculateSkipability,
};
// / INTERNAL METHODS
