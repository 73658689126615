import * as React from "react";
import { createPortal } from "react-dom";

import { zigProviderAnchorId } from "../ZigProvider";

export const Anchor = (props: React.PropsWithChildren<{}>) => {
	const ZigPopupAnchor = document.getElementById(zigProviderAnchorId);

	if (!ZigPopupAnchor) {
		return null;
	}

	return createPortal(props.children, ZigPopupAnchor);
};
