import { styled } from "../../utils/theme";

export const LayoutWrapper = styled.div`
	${({ theme }) => theme.fn.paddingSquare("xxs")}
`;

export const Header = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

export const Title = styled.div`
	// nothing
`;

export const Actions = styled.div`
	// nothing
`;

export const Content = styled.div`
	${({ theme }) => theme.fn.margin({ top: "xxs" })}
`;
