import { styled } from "../../utils/theme";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	> div {
		> div {
			&:nth-child(3) {
				border-radius: 0;
			}
		}
	}

	div:nth-child(2) {
		div:nth-child(1) {
			div:nth-child(2) {
				border-radius: 0;
			}
		}
	}
`;
