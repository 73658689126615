import { styled } from "../../utils/theme";
import { GridProps } from "./types";

export const GridMain = styled.div<GridProps>`
	display: grid;
	${p =>
		p.columns
			? `grid-template-columns: repeat(${p.columns ?? "auto"},${p.auto ? "auto" : "1fr"});`
			: ""}
	${p =>
		p.rows ? `grid-template-rows: repeat(${p.rows ?? "auto"},${p.auto ? "auto" : "1fr"});` : ""}

	grid-gap: ${p => `${p.theme.spacing[p.verticalGap ?? p.gap]} ${p.theme.spacing[p.gap]}`};
	${p => p.theme.fn.paddingSquare(p.padding)}
`;
