import React from "react";
import { ThemeProvider } from "styled-components";

import { theme } from "../../utils/theme";
import GlobalStyle from "../../utils/theme/global";

export const zigProviderAnchorId = "zig-popup-anchor";

export function ZigProvider(props: React.PropsWithChildren<{}>) {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<div id={zigProviderAnchorId}>{props.children}</div>
		</ThemeProvider>
	);
}
