import * as React from "react";

import { Props as BasicInputProps } from "../../../components/Inputs/basic";
import { Group } from "../../../components/Inputs/styles";
import { Input } from "./basic";
import { Addon } from "./styles";

export interface CustomInputProps extends Omit<BasicInputProps, "prefix" | "suffix"> {
	prefix?: React.ReactNode;
	suffix?: React.ReactNode;
}

export const CustomInput = (props: CustomInputProps) => {
	const { prefix, suffix, ...restOfProps } = props;

	return (
		<Group>
			<Input {...restOfProps} />
			<Addon
				error={props.error}
				warning={props.warning}
				onClick={props.disabled ? undefined : props.onClick}
			>
				{prefix}
			</Addon>
			<Addon
				error={props.error}
				warning={props.warning}
				onClick={props.disabled ? undefined : props.onClick}
			>
				{suffix}
			</Addon>
		</Group>
	);
};
