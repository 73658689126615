export function phoneFormatter(phoneRaw: string) {
	const phoneWithoutSymbols = phoneRaw.replace(/\D/gu, "");
	let phone = "";
	let prefix = "";

	if (phoneWithoutSymbols.length > 8) {
		phone = phoneWithoutSymbols.substr(phoneWithoutSymbols.length - 9);
		phone = `${phone.substr(0, 5)}-${phone.substr(5)}`;

		prefix = phoneWithoutSymbols.substr(0, phoneWithoutSymbols.length - 9);
		prefix = `${prefix.substr(0, prefix.length - 2)} (${prefix.substr(prefix.length - 2)})`;

		if (phoneRaw.trim()[0] === "+") {
			prefix = `+${prefix}`;
		}

		return `${prefix} ${phone}`.trim();
	} else if (phoneWithoutSymbols.length === 8) {
		phone = phoneWithoutSymbols;
		return `${phone.substr(0, 4)}-${phone.substr(4)}`;
	}

	return phoneWithoutSymbols;
}
