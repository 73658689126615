import { BtnGroup, DefaultButtonStyle } from "./styles";
import React, { ButtonHTMLAttributes, ReactNode } from "react";

import { Loading } from "../Loading";
import { theme } from "../../utils/theme";

type ButtonType = "primary" | "secondary" | "ghost" | "danger" | "warn";

export interface IDefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
	backgroundColor: string;
	hoverColor: string;
	textColor: string;
	loadingColor: string;
	loadingSize?: number;
	isLoading?: boolean;
	isDisabled?: boolean;
	notFilled?: boolean;
	secondaryStyle?: boolean;
	width?: string | number;
	size?: "small" | "default" | "large";
}

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
	typeButton?: ButtonType;
	loadingSize?: number;
	isLoading?: boolean;
	isDisabled?: boolean;
	notFilled?: boolean;
	width?: string | number;
	size?: "small" | "default" | "large";
}

export function DefaultButton({
	children,
	isLoading,
	loadingColor,
	isDisabled,
	disabled,
	loadingSize = 14,
	notFilled,
	backgroundColor,
	...props
}: IDefaultButtonProps) {
	return (
		<DefaultButtonStyle
			{...props}
			isLoading={isLoading}
			disabled={disabled ?? isDisabled ?? isLoading}
			backgroundColor={backgroundColor}
			notFilled={notFilled}
			isDisabled={disabled ?? isDisabled}
		>
			{isLoading ? (
				<Loading color={notFilled ? backgroundColor : loadingColor} size={loadingSize} />
			) : (
				children
			)}
		</DefaultButtonStyle>
	);
}

export function Button({ typeButton = "primary", children, ...props }: IButtonProps) {
	switch (typeButton) {
		case "primary":
			return (
				<DefaultButton
					backgroundColor={theme.colors.brand.primary.medium}
					loadingColor={theme.colors.others.light.light}
					hoverColor={theme.colors.brand.primary.dark}
					textColor={theme.colors.others.light.light}
					{...props}
				>
					{children}
				</DefaultButton>
			);
		case "secondary":
			return (
				<DefaultButton
					backgroundColor={theme.colors.others.light.light}
					loadingColor={theme.colors.others.dark.dark}
					hoverColor={theme.colors.others.light.light}
					textColor={theme.colors.others.dark.dark}
					secondaryStyle
					{...props}
				>
					{children}
				</DefaultButton>
			);
		case "ghost":
			return (
				<DefaultButton
					backgroundColor="unset"
					loadingColor={theme.colors.brand.primary.medium}
					hoverColor="rgba(63, 186, 212, 0.16)"
					textColor={theme.colors.brand.primary.medium}
					{...props}
				>
					{children}
				</DefaultButton>
			);
		case "danger":
			return (
				<DefaultButton
					backgroundColor={theme.colors.others.error.medium}
					loadingColor={theme.colors.others.light.light}
					hoverColor={theme.colors.others.error.dark}
					textColor={theme.colors.others.light.light}
					{...props}
				>
					{children}
				</DefaultButton>
			);
		case "warn":
			return (
				<DefaultButton
					backgroundColor={theme.colors.others.warning.medium}
					loadingColor={theme.colors.others.light.light}
					hoverColor={theme.colors.others.warning.dark}
					textColor={theme.colors.others.light.light}
					{...props}
				>
					{children}
				</DefaultButton>
			);
		default:
			return (
				<DefaultButton
					backgroundColor={theme.colors.brand.primary.medium}
					loadingColor={theme.colors.others.light.light}
					hoverColor={theme.colors.brand.primary.darkest}
					textColor={theme.colors.others.light.light}
					{...props}
				>
					{children}
				</DefaultButton>
			);
	}
}

export function ButtonGroup(
	props: React.PropsWithChildren<{
		className?: string;
	}>,
) {
	return <BtnGroup className={props.className}>{props.children}</BtnGroup>;
}
