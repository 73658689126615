import React from "react";

import { LoadingWrapper, LoadingCircle } from "./styles";

export interface Props {
	height?: number;
}

export function FullSpinner(props: Props) {
	return (
		<LoadingWrapper style={props.height !== undefined ? { height: props.height } : undefined}>
			<LoadingCircle />
		</LoadingWrapper>
	);
}
