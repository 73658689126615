import * as React from "react";

import { BasicInput } from "./styles";

export interface Props
	extends Omit<
		React.InputHTMLAttributes<HTMLInputElement>,
		"onChange" | "onBlur" | "value" | "onFocus"
	> {
	onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
	value?: string;
	placeholder?: string;
	type?: string;
	autoFocus?: boolean;
	id?: string;
	disabled?: boolean;
	error?: boolean;
	warning?: boolean;
}

export const Input = (props: Props) => {
	const { onChange, onBlur, onFocus, ...restOfProps } = props;

	return (
		<BasicInput
			onChange={ev => onChange && onChange(ev.target.value, ev)}
			onBlur={ev => onBlur && onBlur(ev.target.value, ev)}
			onFocus={ev => onFocus && onFocus(ev.target.value, ev)}
			{...restOfProps}
		/>
	);
};
