import { css, DefaultTheme as DefaultThemeStyled } from "styled-components";

import { styled } from "../../utils/theme";

interface IDefaultButtonStyle {
	backgroundColor: string;
	hoverColor: string;
	textColor: string;
	isLoading?: boolean;
	isDisabled?: boolean;
	notFilled?: boolean;
	secondaryStyle?: boolean;
	width?: string | number;
	size?: "small" | "default" | "large";
}

type DefaultTheme = DefaultThemeStyled & IDefaultButtonStyle;

function notFilledColor(bgColor: string, textColor: string, textAndBgSameColor: boolean) {
	if (bgColor === "unset") {
		return textColor;
	}

	if (textAndBgSameColor) {
		return textColor;
	}

	return bgColor;
}

export const DefaultButtonStyle = styled.button<IDefaultButtonStyle>`
	${({ theme }) => theme.fn.borderRadius("lg")};
	${({ theme }) => theme.fn.paddingSquish("nano")};
	${({ theme }) => theme.fn.fontWeight("bold")};
	${({ theme }) => theme.font.lato};

	outline: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	border: ${({ backgroundColor }) =>
		backgroundColor === "unset" ? 0 : `2px solid ${backgroundColor}`};
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: ${({ textColor }) => textColor};
	font-size: 14px;

	${({ width }) => {
		if (typeof width === "number") {
			return `width: ${width}px;`;
		}

		if (typeof width === "string") {
			return `width: ${width};`;
		}

		return `width: auto;`;
	}}

	transition: opacity 0.4s, background-color 0.4s, border 0.4s;
	cursor: pointer;

	&:hover {
		background-color: ${({ hoverColor }) => hoverColor};
		border: 2px solid ${({ hoverColor }) => hoverColor};
		border: ${({ backgroundColor, hoverColor }) =>
			backgroundColor === "unset" ? 0 : `2px solid ${hoverColor}`};
	}

	${({ size }) => {
		switch (size) {
			case "small":
				return css<DefaultTheme>`
					${({ theme }) => theme.fn.fontSize("xxs")};
					${({ theme }) => theme.fn.paddingSquish("quark")};
				`;
			case "default":
				return null;
			case "large":
				return css<DefaultTheme>`
					${({ theme }) => theme.fn.fontSize("xs")};
					${({ theme }) => theme.fn.paddingSquish("xxxs")};
				`;
			default:
				return null;
		}
	}};

	${({ secondaryStyle }) =>
		secondaryStyle &&
		css<DefaultTheme>`
			border: 2px solid ${({ theme }) => theme.colors.others.light.darkest};
			color: ${({ theme }) => theme.colors.others.dark.dark};

			transition: border 0.4s, color 0.4s;

			&:hover {
				border: 2px solid ${({ theme }) => theme.colors.brand.primary.medium};
				color: ${({ theme }) => theme.colors.brand.primary.medium};
			}
		`};

	${({ isLoading }) =>
		isLoading &&
		css<DefaultTheme>`
			cursor: not-allowed;
			opacity: ${({ theme }) => theme.opacityLevels.intense};

			&:hover {
				background-color: ${({ backgroundColor, hoverColor }) =>
					backgroundColor === "unset" ? hoverColor : backgroundColor}
				border: 2px solid ${({ backgroundColor }) => backgroundColor};
			}
		`};

	${({ isDisabled }) =>
		isDisabled &&
		css<DefaultTheme>`
			cursor: not-allowed;
			background-color: ${({ theme }) => theme.colors.others.light.darkest};
			color: ${({ theme }) => theme.colors.others.dark.medium};
			border: 2px solid ${({ theme }) => theme.colors.others.light.darkest};

			&:hover {
				background-color: ${({ theme }) => theme.colors.others.light.darkest};
				border: 2px solid ${({ theme }) => theme.colors.others.light.darkest};
				color: ${({ theme }) => theme.colors.others.dark.medium};
			}
		`};

	${({ notFilled, secondaryStyle }) =>
		notFilled &&
		css<DefaultTheme>`
			background-color: ${({ theme }) => theme.colors.others.light.light};
			color: ${({ backgroundColor, textColor }) =>
				notFilledColor(backgroundColor, textColor, Boolean(secondaryStyle))};
			border: 2px solid ${({ backgroundColor }) => backgroundColor};

			&:hover {
				${({ theme }) => theme.fn.opacity("intense")};
				background-color: ${({ theme }) => theme.colors.others.light.light};
				border: 2px solid ${({ backgroundColor }) => backgroundColor};
			}
		`};
`;

export const BtnGroup = styled.div`
	display: flex;

	button:first-child {
		border-radius: 10px 0px 0px 10px;
	}

	button:not(:first-child):not(:last-child) {
		border-radius: 0px;
	}

	button:last-child {
		border-radius: 0px 10px 10px 0px;
	}
`;
