import React, { TextareaHTMLAttributes } from "react";

import { TextareaStyle } from "./styles";

interface ITextareaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
	error?: boolean;
	warning?: boolean;
	onChange?: (value: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export function Textarea({ onChange, ...props }: ITextareaProps) {
	return <TextareaStyle onChange={ev => onChange && onChange(ev.target.value, ev)} {...props} />;
}
