import baseStyled, { ThemedStyledInterface } from "styled-components";

import { Maped } from "../types";

const font = {
	importLato: `@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');`,
	lato: "font-family: 'Lato', sans-serif;",
	importUbuntu:
		"@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');",
	ubuntu: "font-family: 'Ubuntu', sans-serif;",
};

const colors = {
	b2c: {
		green: {
			regular: "#7FD344",
			dark: "#3D6621",
		},
		blue: "#3FABD9",
		pink: "#E840A8",
	},
	brand: {
		gradient: {
			begin: "#62D171",
			end: "#59ACBE",
		},
		primary: {
			darkest: "#236876",
			dark: "#127589",
			medium: "#3FBAD4",
			regular: "#71CCDF",
			light: "#8BDEEF",
		},
		secondary: {
			darkest: "#244C3C",
			dark: "#499979 ",
			medium: "#62CEA3",
			regular: "#6DE5B5",
			light: "#B2FFE0",
		},
	},
	graphs: {
		G1: "#8469D0",
		G2: "#E1B0F2",
		G3: "#EA41A6",
		G4: "#D38C8C",
		G5: "#F2B0B0",
		G6: "#B0F2E6",
		G7: "#8DAEEC",
		G8: "#A2D5E5",
		G9: "#68A4B1",
		G10: "#D0F2B0",
		G11: "#F1F2B0",
		G12: "#C7B306",
		G13: "#F3C876",
		G14: "#F2D4B0",
	},
	others: {
		dark: {
			darkest: "#222222",
			dark: "#4C4C4C",
			medium: "#828282",
			regular: "#A3A4A4",
			light: "#B2B2B2",
		},
		light: {
			darkest: "#CCCCCC",
			dark: "#E5E5E5",
			medium: "#F2F2F2",
			regular: "#FAFAFA",
			light: "#FFFFFF",
		},
		error: {
			darkest: "#660E13",
			dark: "#B21921",
			medium: "#F5222D",
			regular: "#F5626A",
			light: "#F5ABAF",
		},
		warning: {
			darkest: "#9F7300",
			dark: "#CF9600",
			medium: "#FFCB44",
			regular: "#FFD874",
			light: "#FFE39B",
		},
		success: {
			darkest: "#244C3C",
			dark: "#499979",
			medium: "#69C0E3",
			regular: "#3FBAD4",
			light: "#3FBAD4",
		},
		info: {
			darkest: "#24394C ",
			dark: "#497299",
			medium: "#629ACE",
			regular: "#6DABE5",
			light: "#B2DAFF",
		},
	},
};

// BORDERS

export type borderRadiusType = "none" | "sm" | "md" | "lg" | "pill" | "circular";
const radius: Maped<borderRadiusType> = {
	none: "0px",
	sm: "8px",
	md: "16px",
	lg: "24px",
	pill: "500px",
	circular: "50%",
};

function borderRadius(type: borderRadiusType): string {
	return `border-radius : ${radius[type]};`;
}

export type borderWidthsType = "none" | "hairline" | "thin" | "thick" | "heavy";
const widths: Maped<borderWidthsType> = {
	none: "0px",
	hairline: "1px",
	thin: "2px",
	thick: "4px",
	heavy: "8px",
};

function borderWidth(type: borderWidthsType): string {
	return `
        border-width : ${widths[type]};
        border-style: solid;
    `;
}

// OPACITY

export type opacityType =
	| "none"
	| "semi-opaque"
	| "intense"
	| "medium"
	| "light"
	| "semi-transparent";
const opacityLevels: Maped<opacityType> = {
	none: "none",
	"semi-opaque": "0.80",
	intense: "0.64",
	medium: "0.32",
	light: "0.16",
	"semi-transparent": "0.08",
};

function opacity(type: opacityType): string {
	return `opacity: ${opacityLevels[type]}; `;
}

// SPACING

export type spacingSize =
	| "none"
	| "quark"
	| "nano"
	| "xxxs"
	| "xxs"
	| "xs"
	| "sm"
	| "md"
	| "lg"
	| "xm"
	| "xl"
	| "xxl"
	| "xxxl"
	| "huge"
	| "giant";
export type sides = "top" | "bottom" | "right" | "left";

const spacing: Maped<spacingSize> = {
	none: "0px",
	quark: "4px",
	nano: "8px",
	xxxs: "16px",
	xxs: "24px",
	xs: "32px",
	sm: "40px",
	md: "48px",
	lg: "56px",
	xm: "64px",
	xl: "72px",
	xxl: "80px",
	xxxl: "120px",
	huge: "160px",
	giant: "200px",
};

export type paddingConfig = Partial<Record<sides, spacingSize>>;
function padding(config: paddingConfig) {
	let padding = "";

	if (config.top) {
		padding += `padding-top: ${spacing[config.top]}; \n`;
	}

	if (config.bottom) {
		padding += `padding-bottom: ${spacing[config.bottom]}; \n`;
	}

	if (config.left) {
		padding += `padding-left: ${spacing[config.left]}; \n`;
	}

	if (config.right) {
		padding += `padding-right: ${spacing[config.right]}; \n`;
	}

	return padding;
}

function paddingSquare(size: spacingSize) {
	return `padding: ${spacing[size]}; `;
}

function paddingSquish(size: spacingSize) {
	const equivalence: Record<spacingSize, spacingSize> = {
		none: "quark",
		quark: "nano",
		nano: "xxxs",
		xxxs: "xxs",
		xxs: "xs",
		xs: "sm",
		sm: "md",
		md: "lg",
		lg: "xm",
		xm: "xl",
		xl: "xxl",
		xxl: "xxxl",
		xxxl: "huge",
		huge: "giant",
		giant: "giant",
	};

	return `padding: ${spacing[size]} ${spacing[equivalence[size]]}; `;
}

export type marginConfig = Partial<Record<sides, spacingSize>>;
function margin(config: marginConfig) {
	let margin = "";

	if (config.top) {
		margin += `margin-top: ${spacing[config.top]} \n`;
	}

	if (config.bottom) {
		margin += `margin-bottom: ${spacing[config.bottom]} \n`;
	}

	if (config.left) {
		margin += `margin-left: ${spacing[config.left]} \n`;
	}

	if (config.right) {
		margin += `margin-right: ${spacing[config.right]} \n`;
	}

	return margin;
}

// SHADOW

function shadowLevel(level: number) {
	if (level === 4) {
		return `box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.08); `;
	}

	if (level === 3) {
		return `box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08); `;
	}

	if (level === 2) {
		return `box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08); `;
	}

	return `box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08); `;
}

// TYPOGRAHPY
export type fontSizes = "xxxs" | "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
const fontSizing: Maped<fontSizes> = {
	xxxs: "8px",
	xxs: "12px",
	xs: "16px",
	sm: "20px",
	md: "24px",
	lg: "32px",
	xl: "40px",
	xxl: "48px",
	xxxl: "56px",
};

function fontSize(size: fontSizes) {
	return `font-size: ${fontSizing[size]}; `;
}

export type lineHeightsType = "near" | "compact" | "far" | "further";
const lineHeights: Maped<lineHeightsType> = {
	near: "100%",
	compact: "120%",
	far: "150%",
	further: "200%",
};

function lineHeight(size: lineHeightsType) {
	return `line-height: ${lineHeights[size]}; `;
}

export type fontWeightsType = "thin" | "light" | "regular" | "bold" | "black";
const fontWeights: Maped<fontWeightsType> = {
	thin: "100",
	light: "300",
	regular: "400",
	bold: "700",
	black: "900",
};

function fontWeight(size: fontWeightsType) {
	return `font-weight: ${fontWeights[size]}; `;
}

export const theme = {
	font,
	colors,
	radius,
	widths,
	opacityLevels,
	spacing,
	fontSizing,
	lineHeights,
	fontWeights,
	fn: {
		borderRadius,
		borderWidth,
		opacity,
		padding,
		paddingSquare,
		paddingSquish,
		margin,
		shadowLevel,
		fontSize,
		lineHeight,
		fontWeight,
	},
};

export type ThemeType = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<ThemeType>;
