import * as React from "react";

import { Sbutton } from "./style";

export interface IStyleRelativeProps {
	type?: "default" | "primary" | "secondary" | "negative" | "positive";
	size?: "default" | "small" | "large";
	loading?: boolean;
	buttonXls?: boolean;
	disabled?: boolean;
}

export interface IButtonProps extends IStyleRelativeProps {
	style?: React.CSSProperties;
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement, React.MouseEvent>) => void;
}

export class Button extends React.Component<IButtonProps> {
	public static defaultProps = {
		type: "default",
		size: "default",
	};

	render() {
		const { children, ...rest } = this.props;

		// @ts-ignore
		return <Sbutton {...rest}>{children}</Sbutton>;
	}
}
