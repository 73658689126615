import * as React from "react";

import { currencyMask } from "../../helpers/currencyMask";
import { stringToNumber } from "../../helpers/stringToNumber";
import { Props as BasicInputProps } from "./basic";
import { CustomInput } from "./custom";

interface Props extends Omit<BasicInputProps, "onChange" | "onBlur" | "value"> {
	value?: number | null;
	onChange?: (v: number | null) => void;
	onBlur?: (v: number | null) => void;
}

export function Percentage(props: Props) {
	const { value, onBlur, onChange, placeholder, ...otherProps } = props;

	const inputValue = currencyMask(value ?? undefined, 2, ",");
	const inputOnChange = (v: string | undefined) => onChange && onChange(stringToNumber(v));
	const inputOnBlur = (v: string | undefined) => onBlur && onBlur(stringToNumber(v));

	return (
		<CustomInput
			suffix="%"
			placeholder={placeholder ?? "0,00"}
			value={inputValue}
			onChange={inputOnChange}
			onBlur={inputOnBlur}
			{...otherProps}
		/>
	);
}
