import React from "react";

import { UBEMask } from "../../helpers/UBEMask";
import { NumberInput } from "../Inputs/number";
import { Select } from "../Select/basic";
import { Container } from "./styles";

export type BasicStorageUnit = "milliliter" | "liter" | "kilogram" | "gram" | "unit";

interface Props {
	value: number;
	currentUnit: BasicStorageUnit;
	onChange: (v: number) => void;
	disabled?: boolean;
	error?: boolean;
	warning?: boolean;
}

export const UBELabels: Record<BasicStorageUnit, string> = {
	gram: "Gramas",
	kilogram: "Kilogramas",
	liter: "Litros",
	milliliter: "Mililitros",
	unit: "Unidades",
};

const hasMask: Record<BasicStorageUnit, boolean> = {
	gram: false,
	milliliter: false,
	kilogram: true,
	liter: true,
	unit: false,
};

const optionsAvailable: Record<BasicStorageUnit, BasicStorageUnit[]> = {
	gram: ["gram", "kilogram"],
	milliliter: ["milliliter", "liter"],
	kilogram: ["kilogram", "gram"],
	liter: ["liter", "milliliter"],
	unit: ["unit"],
};

export function InputUBE(props: Props) {
	const [viewUnit, setViewUnit] = React.useState<BasicStorageUnit>(props.currentUnit);

	React.useEffect(() => {
		setViewUnit(props.currentUnit);
	}, [props.currentUnit]);

	return (
		<Container>
			<NumberInput
				min={0}
				value={props.value}
				onChange={v => props.onChange(v ?? 0)}
				formatter={value => (hasMask[viewUnit] ? UBEMask(value).toString() : String(value))}
				disabled={props.disabled}
				error={props.error}
				warning={props.warning}
			/>
			<Select
				options={optionsAvailable[props.currentUnit].map(option => ({
					value: option,
					label: UBELabels[option],
				}))}
				value={viewUnit}
				onChange={v => setViewUnit(v)}
				disabled={props.disabled}
				error={props.error}
				warning={props.warning}
			/>
		</Container>
	);
}
