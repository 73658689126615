import React, { TextareaHTMLAttributes } from "react";

import { TextareaB2CStyle } from "./styles";

interface ITextareaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
	error?: boolean;
	warning?: boolean;
	noBorder?: boolean;
	onChange?: (value: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export function Textarea({ onChange, noBorder = false, ...props }: ITextareaProps) {
	return (
		<TextareaB2CStyle
			onChange={(value, ev) => onChange && onChange(value, ev)}
			noBorder={noBorder}
			{...props}
		/>
	);
}
