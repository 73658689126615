import React from "react";

import { IOptions } from "../../utils/types";
import { Option, ListOptionsStyle } from "./style";

interface IListOptionsProps {
	show: boolean;
	handleOptions: IOptions[];
	onClose: () => void;
	onChange?: (value: any) => void;
	onSearch?: (value: string) => void;
}

export function ListOptions({
	show,
	handleOptions,
	onClose,
	onChange,
	onSearch,
}: IListOptionsProps) {
	return (
		<>
			{show && (
				<ListOptionsStyle>
					{handleOptions.length > 0 &&
						handleOptions.map(option => (
							<Option
								key={option.value}
								onClick={() => {
									onChange && onChange(option.value);
									onSearch && onSearch(option.label);
									onClose();
								}}
							>
								{option.label}
							</Option>
						))}

					{handleOptions.length === 0 && <Option disabled>Não há dados</Option>}
				</ListOptionsStyle>
			)}
		</>
	);
}
