import { styled } from "../../utils/theme";

interface IOptionStyle {
	disabled?: boolean;
}

export const ListOptionsStyle = styled.div`
	${({ theme }) => theme.font.lato};
	${({ theme }) => theme.fn.borderRadius("md")};
	${({ theme }) => theme.fn.borderWidth("hairline")};

	position: absolute;

	width: 100%;
	max-height: 180px;
	overflow-y: auto;

	display: flex;
	flex-direction: column;

	background-color: ${({ theme }) => theme.colors.others.light.light};
	border-color: ${({ theme }) => theme.colors.others.light.darkest};

	z-index: 1;

	&::-webkit-scrollbar {
		background-color: ${({ theme }) => theme.colors.others.light.light};
		width: 16px;
	}

	&::-webkit-scrollbar-track {
		background-color: ${({ theme }) => theme.colors.others.light.light};
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.colors.others.light.darkest};
		border-radius: 16px;
		border: 4px solid ${({ theme }) => theme.colors.others.light.light};
	}

	&::-webkit-scrollbar-button {
		display: none;
	}
`;

export const Option = styled.div<IOptionStyle>`
	${({ theme }) => theme.font.lato};
	${({ theme }) => theme.fn.paddingSquish("nano")};
	${({ theme }) => theme.fn.fontSize("xxs")};

	width: 100%;

	color: ${({ theme }) => theme.colors.others.dark.dark};
	cursor: ${props => (props.disabled ? "default" : "pointer")};

	transition: background-color 0.2s;

	&:hover {
		background-color: rgba(63, 186, 212, 0.32);
	}

	&:first-child {
		border-radius: 16px 16px 0 0;
	}

	&:last-child {
		border-radius: 0 0 16px 16px;
	}
`;
