import * as React from "react";

import { PositionType, Tooltip } from "../Tooltip";
import { TM } from "../Typography";
import { Content, ErrorMessage, RequiredSign, WarningMessage } from "./styles";

interface Props {
	label: React.ReactNode;
	className?: string;
	required?: boolean;
	errorMessage?: string;
	warningMessage?: string;
	tooltip?: string;
	tooltipPosition?: PositionType;
}

export const Label = (props: React.PropsWithChildren<Props>) => {
	const {
		label,
		children,
		required,
		errorMessage,
		warningMessage,
		tooltip,
		tooltipPosition,
		className,
	} = props;

	// maybe this is ❋ is a better option
	const requiredMark = required ? <RequiredSign>&nbsp;✱</RequiredSign> : null;

	const warning = warningMessage ? (
		<WarningMessage>
			<TM fontWeight="bold">{warningMessage}</TM>
		</WarningMessage>
	) : null;
	const error = errorMessage ? (
		<ErrorMessage>
			<TM fontWeight="bold">{errorMessage}</TM>
		</ErrorMessage>
	) : null;

	return (
		<div className={className}>
			<div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
				<TM fontWeight="bold" style={{ marginRight: 10, fontSize: 14 }}>
					{label}
					{requiredMark}
				</TM>
				{tooltip ? <Tooltip message={tooltip} position={tooltipPosition} /> : <div />}
			</div>
			<Content>{children}</Content>
			{error}
			{warning}
		</div>
	);
};
