import React from "react";

import { Text } from "./styles";

interface Props {
	testing?: "just testing";
}

export function HelloWorld(_props: Props) {
	return <Text>Hello World</Text>;
}
