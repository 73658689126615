import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

  * {
    box-sizing: border-box;
  }

  /* * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, textarea {
    font-family: 'Lato', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  } */

  // our custom modal config
  .rc-dialog-content {
    border-radius: 16px !important;
    box-shadow: 0px 16px 32px rgba(34, 34, 34, 0.16);
    animation-name: growIn;
    animation-duration: 0.2s;
  }
  .rc-dialog-mask {
    background-color: rgba(0, 0, 0, 0.05) !important;
    /* backdrop-filter: blur(1px) !important; */
    backdrop-filter: grayscale(0.5) !important;
    animation-name: fadeIn;
    animation-duration: 0.2s;
  }

  .rc-dialog{
    margin: 200px auto !important;
  }

  .rc-dialog-body{
    border-radius: 16px !important;
    padding: 0px !important;
  }

  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

  @keyframes growIn {
    0% {transform: scale(0.8);}
    100% {opacity: scale(1);}
  } 

  // lib default
  .rc-dialog {
    position: relative;
    width: auto;
    margin: 10px;
  }
  .rc-dialog-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  .rc-dialog-title {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
  }
  .rc-dialog-content {
    position: relative;
    background-color: #ffffff;
    border: none;
    border-radius: 6px 6px;
    background-clip: padding-box;
  }
  .rc-dialog-close {
    cursor: pointer;
    border: 0;
    background: transparent;
    font-size: 21px;
    position: absolute;
    right: 20px;
    top: 12px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
    text-decoration: none;
  }
  .rc-dialog-close-x:after {
    content: '×';
  }
  .rc-dialog-close:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    text-decoration: none;
  }
  .rc-dialog-header {
    padding: 13px 20px 14px 20px;
    border-radius: 5px 5px 0 0;
    background: #fff;
    color: #666;
    border-bottom: 1px solid #e9e9e9;
  }
  .rc-dialog-body {
    padding: 20px;
  }
  .rc-dialog-footer {
    border-top: 1px solid #e9e9e9;
    padding: 10px 20px;
    text-align: right;
    border-radius: 0 0 5px 5px;
  }
  .rc-dialog-zoom-enter,
  .rc-dialog-zoom-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }
  .rc-dialog-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }
  .rc-dialog-zoom-enter.rc-dialog-zoom-enter-active,
  .rc-dialog-zoom-appear.rc-dialog-zoom-appear-active {
    animation-name: rcDialogZoomIn;
    animation-play-state: running;
  }
  .rc-dialog-zoom-leave.rc-dialog-zoom-leave-active {
    animation-name: rcDialogZoomOut;
    animation-play-state: running;
  }
  @keyframes rcDialogZoomIn {
    0% {
      opacity: 0;
      transform: scale(0, 0);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  @keyframes rcDialogZoomOut {
    0% {
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform: scale(0, 0);
    }
  }
  @media (min-width: 768px) {
    .rc-dialog {
      width: 600px;
      margin: 30px auto;
    }
  }
  .rc-dialog-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #373737;
    background-color: rgba(55, 55, 55, 0.6);
    height: 100%;
    filter: alpha(opacity=50);
    z-index: 1050;
  }
  .rc-dialog-mask-hidden {
    display: none;
  }
  .rc-dialog-fade-enter,
  .rc-dialog-fade-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  .rc-dialog-fade-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  .rc-dialog-fade-enter.rc-dialog-fade-enter-active,
  .rc-dialog-fade-appear.rc-dialog-fade-appear-active {
    animation-name: rcDialogFadeIn;
    animation-play-state: running;
  }
  .rc-dialog-fade-leave.rc-dialog-fade-leave-active {
    animation-name: rcDialogFadeOut;
    animation-play-state: running;
  }
  @keyframes rcDialogFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes rcDialogFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

`;
