import React from "react";

import { theme } from "../../utils/theme";
import { LoadingWrapper, LoadingCircle } from "./styles";

interface ILoadingProps {
	size?: number;
	color?: string;
}

export function Loading({ color = theme.colors.brand.primary.medium, size = 36 }: ILoadingProps) {
	return (
		<LoadingWrapper>
			<LoadingCircle color={color} size={size} />
		</LoadingWrapper>
	);
}
