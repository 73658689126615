import React from "react";

import { GridMain } from "./styles";
import { GridProps } from "./types";

export function Grid(props: React.PropsWithChildren<GridProps>) {
	return (
		<GridMain
			columns={props.columns}
			gap={props.gap}
			verticalGap={props.verticalGap}
			padding={props.padding}
			auto={props.auto}
		>
			{props.children}
		</GridMain>
	);
}
