import styled from "styled-components";

export const SFieldImage = styled.div<any>`
	display: flex;
	width: 65px;
`;

export const SImageBox = styled.div<any>`
	position: relative;
	width: 65px;
	height: 65px;
	border-radius: 50%;
	border: "1px dashed #d9d9d9";
	background-color: #fafafa;
	background-size: cover;
	background-position: center;
	transition: border-color 0.3s ease;
	overflow: hidden;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	&:hover {
		border-color: #00a5a6;
	}

	span {
		text-transform: uppercase;
		margin: 0;
		line-height: 1;
		color: rgba(0, 0, 0, 0.65);
		font-size: 26px;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		cursor: pointer;
	}
`;
