import { styled } from "../../utils/theme";

interface IImageUploadProps {
	size?: number;
}

export const Container = styled.div<IImageUploadProps>`
	label {
		cursor: pointer;
		transition: opacity 0.2s;

		&:hover {
			opacity: 0.7;
		}

		img {
			height: ${props => (props.size ? `${props.size}px` : "120px")};
			width: ${props => (props.size ? `${props.size}px` : "120px")};
			border-radius: 50%;
		}

		input {
			display: none;
		}
	}
`;

export const LabelToUpload = styled.div<IImageUploadProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	height: ${props => (props.size ? `${props.size}px` : "120px")};
	width: ${props => (props.size ? `${props.size}px` : "120px")};
	border-radius: 50%;
	border: 3px dashed ${props => props.theme.colors.others.light.darkest};
	background-color: ${props => props.theme.colors.others.light.medium};

	transition: opacity 0.2s;

	p {
		${props => props.theme.font.lato}
		font-size: 16px;
		text-align: center;
		color: ${props => props.theme.colors.others.dark.medium};

		max-width: 80px;
		margin: 0;
		margin-top: 10px;
	}

	&:hover {
		opacity: 0.7;
	}
`;
