import { styled } from "../../utils/theme";

// BASIC INPUT

interface InputProps {
	error?: boolean;
	warning?: boolean;
}

export const BasicInput = styled.input<InputProps>`
	all: unset;
	box-sizing: border-box;
	${({ theme }) => theme.font.lato}
	min-width: ${({ theme }) => theme.spacing.huge};
	/* height: ${({ theme }) => theme.spacing.xxs}; */
	background-color: ${({ theme }) => theme.colors.others.light.light};
	transition: all 0.3s ease;

	color: ${({ theme }) => theme.colors.others.dark.darkest};

	${({ theme }) => theme.fn.paddingSquish("nano")}
	${({ theme }) => theme.fn.borderRadius("lg")}
    ${({ theme }) => theme.fn.borderWidth("hairline")}
    ${({ theme }) => theme.fn.fontWeight("regular")}
    ${({ theme }) => theme.fn.fontSize("xxs")}
    ${({ theme }) => theme.fn.lineHeight("compact")}

    ${({ error, warning, theme }) => {
		if (error) {
			return `border-color: ${theme.colors.others.error.medium};`;
		}

		if (warning) {
			return `border-color: orange;`;
		}

		return `
                /* border-color: ${theme.colors.others.dark.light}; */
				border-color: #d9d9d9;
                &:active, &:focus {
                    border-color: ${theme.colors.brand.primary.medium};
                }
            `;
	}}

   
    &:disabled {
		background-color: ${({ theme }) => theme.colors.others.light.medium};
		border-color: ${({ theme }) => theme.colors.others.dark.light};
		color: ${({ theme }) => theme.colors.others.dark.medium};
	}

	input {
		-webkit-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}
`;

// CUSTOM INPUT

export const Addon = styled.div<{ error?: boolean; warning?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	${({ theme }) => theme.font.lato}

	width: fit-content;

	background-color: ${({ theme }) => theme.colors.others.light.light};
	color: ${({ theme }) => theme.colors.others.dark.dark};
	transition: all 0.3s ease;

	background-color: ${({ theme }) => theme.colors.others.light.light};
	color: ${({ theme }) => theme.colors.others.dark.dark};
	transition: all 0.3s ease;

	${({ theme }) => theme.fn.borderWidth("hairline")}
	${({ theme }) => theme.fn.fontWeight("regular")}
    ${({ theme }) => theme.fn.fontSize("xxs")}
    ${({ theme }) => theme.fn.lineHeight("compact")}
    ${({ theme }) => theme.fn.borderRadius("lg")}


    ${({ theme }) => theme.fn.paddingSquish("nano")}

    /* min-width: ${({ theme }) => theme.spacing.xxxs};
     */
    min-width: max-content;
	max-width: fit-content;

	input:disabled ~ & {
		background-color: ${({ theme }) => theme.colors.others.light.medium};
		border-color: ${({ theme }) => theme.colors.others.dark.light};
		color: ${({ theme }) => theme.colors.others.dark.medium};
	}

	${({ error, warning, theme }) => {
		if (error) {
			return `
            border-color: ${theme.colors.others.error.medium};
            color: ${theme.colors.others.error.medium};
        `;
		}

		if (warning) {
			return `
            border-color: orange;
            color: orange;
        `;
		}

		return `
                /* border-color: ${theme.colors.others.dark.light}; */
				border-color: #d9d9d9;
                input:active ~ &,
                input:focus ~ &{
                    border-color: ${theme.colors.brand.primary.medium};
                    color: ${theme.colors.brand.primary.medium};
                }
            `;
	}}

	&:nth-child(2) {
		border-right: 0px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		padding-right: 4px;
		/* background-color: wheat; */
	}
	&:nth-child(3) {
		border-left: 0px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding-left: 4px;
		/* background-color: lightgoldenrodyellow; */
	}
`;

export const Group = styled.div`
	display: grid;
	grid-template-columns: minmax(min-content, max-content) auto minmax(min-content, max-content);
	width: 100%;
	box-sizing: border-box;

	input {
		box-sizing: border-box;

		grid-row: 1;
		grid-column: 2;

		border-left: 0px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding-left: 0px;

		border-right: 0px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		padding-right: 0px;

		width: 100%;

		min-width: auto;

		/* min-width: ${({ theme }) => theme.spacing.xxxl}; */
	}

	${Addon}:nth-child(2) {
		grid-row: 1;
		grid-column: 1;
	}

	${Addon}:nth-child(3) {
		grid-row: 1;
		grid-column: 3;
	}
`;

// LABEL

export const Content = styled.div`
	margin-top: 8px;

	input {
		width: 100%;
	}
`;

export const RequiredSign = styled.span`
	color: ${({ theme }) => theme.colors.others.error.medium};
`;

export const WarningMessage = styled.div`
	margin-left: 4px;
	margin-top: 4px;
	div {
		/* color: ${({ theme }) => theme.colors.others.warning.medium}; */
		color: orange; // all warning colors on DS pallet looks crappy on white bg
	}
`;

export const ErrorMessage = styled.div`
	margin-left: 4px;
	margin-top: 4px;
	div {
		color: ${({ theme }) => theme.colors.others.error.medium};
	}
`;

export const TextareaStyle = styled.textarea<InputProps>`
	all: unset;
	box-sizing: border-box;
	${({ theme }) => theme.font.lato}
	/* min-width: ${({ theme }) => theme.spacing.huge}; */
	/* height: ${({ theme }) => theme.spacing.xxs}; */
	background-color: ${({ theme }) => theme.colors.others.light.light};
	transition: all 0.3s ease;

	color: ${({ theme }) => theme.colors.others.dark.darkest};

	${({ theme }) => theme.fn.paddingSquish("nano")}
	${({ theme }) => theme.fn.borderRadius("lg")}
    ${({ theme }) => theme.fn.borderWidth("hairline")}
    ${({ theme }) => theme.fn.fontWeight("regular")}
    ${({ theme }) => theme.fn.fontSize("xxs")}
    ${({ theme }) => theme.fn.lineHeight("compact")}

    ${({ error, warning, theme }) => {
		if (error) {
			return `border-color: ${theme.colors.others.error.medium};`;
		}

		if (warning) {
			return `border-color: orange;`;
		}

		return `
                /* border-color: ${theme.colors.others.dark.light}; */
				border-color: #d9d9d9;
                &:active, &:focus {
                    border-color: ${theme.colors.brand.primary.medium};
                }
            `;
	}}

   
    &:disabled {
		background-color: ${({ theme }) => theme.colors.others.light.medium};
		border-color: ${({ theme }) => theme.colors.others.dark.light};
		color: ${({ theme }) => theme.colors.others.dark.medium};
	}
`;

export const AutoCompleteContainer = styled.div`
	position: relative;
`;
