import * as React from "react";

import { onlyNumbers } from "../../helpers/onlyNumbers";
import { universalNumberMask } from "../../helpers/universalNumberMask";
import { Props as BasicInputProps } from "./basic";
import { CustomInput } from "./custom";

export const cepMask: Mask = {
	maskIn: (v: string) => universalNumberMask("XX.XXX-XXX", v),
	maskOut: (v: string) => onlyNumbers(v).slice(0, 8),
};

export function CepInput(props: BasicInputProps) {
	const { value, onChange, onBlur, placeholder, ...otherProps } = props;

	const inputValue = cepMask.maskIn(value ?? "");
	const inputOnChange = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onChange && onChange(cepMask.maskOut(v), event);
	const inputOnBlur = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onBlur && onBlur(cepMask.maskOut(v), event);

	return (
		<CustomInput
			prefix="CEP"
			value={inputValue}
			onBlur={inputOnBlur}
			onChange={inputOnChange}
			type="tel"
			placeholder={placeholder ?? "00.000-000"}
			{...otherProps}
		/>
	);
}

export const phoneMask: Mask = {
	maskIn: (v: string) =>
		v.length === 11
			? universalNumberMask("(XX) X XXXX-XXXX", v)
			: universalNumberMask("(XX) XXXX-XXXX", v),
	maskOut: (v: string) => onlyNumbers(v).slice(0, 11),
};

export function PhoneInput(props: BasicInputProps) {
	const { value, onChange, onBlur, placeholder, ...otherProps } = props;

	const inputValue = phoneMask.maskIn(value ?? "");
	const inputOnChange = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onChange && onChange(phoneMask.maskOut(v), event);
	const inputOnBlur = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onBlur && onBlur(phoneMask.maskOut(v), event);

	return (
		<CustomInput
			prefix="Telefone"
			value={inputValue}
			onBlur={inputOnBlur}
			onChange={inputOnChange}
			type="tel"
			placeholder={placeholder ?? "(00) 0 0000-0000"}
			{...otherProps}
		/>
	);
}

export const cpfMask: Mask = {
	maskIn: (v: string) => universalNumberMask("XXX.XXX.XXX-XX", v),
	maskOut: (v: string) => onlyNumbers(v).slice(0, 11),
};

export function CPFInput(props: BasicInputProps) {
	const { value, onChange, onBlur, placeholder, ...otherProps } = props;

	const inputValue = cpfMask.maskIn(value ?? "");
	const inputOnChange = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onChange && onChange(cpfMask.maskOut(v), event);
	const inputOnBlur = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onBlur && onBlur(cpfMask.maskOut(v), event);

	return (
		<CustomInput
			prefix="CPF"
			value={inputValue}
			onBlur={inputOnBlur}
			onChange={inputOnChange}
			type="tel"
			placeholder={placeholder ?? "000.000.000-00"}
			{...otherProps}
		/>
	);
}

export const cnpjMask: Mask = {
	maskIn: (v: string) => universalNumberMask("XX.XXX.XXX/XXXX-XX", v),
	maskOut: (v: string) => onlyNumbers(v).slice(0, 11),
};

export function CNPJInput(props: BasicInputProps) {
	const { value, onChange, onBlur, placeholder, ...otherProps } = props;

	const inputValue = cpfMask.maskIn(value ?? "");
	const inputOnChange = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onChange && onChange(cpfMask.maskOut(v), event);
	const inputOnBlur = (v: string, event: React.ChangeEvent<HTMLInputElement>) =>
		onBlur && onBlur(cpfMask.maskOut(v), event);

	return (
		<CustomInput
			prefix="CPNJ"
			value={inputValue}
			onBlur={inputOnBlur}
			onChange={inputOnChange}
			type="tel"
			placeholder={placeholder ?? "99.999.999/9999-99"}
			{...otherProps}
		/>
	);
}

export function IPInput(props: BasicInputProps) {
	const { placeholder, ...otherProps } = props;

	return (
		<div>
			<CustomInput prefix="IP" type="tel" placeholder={placeholder ?? "0.0.0.0"} {...otherProps} />
		</div>
	);
}

export interface Mask {
	maskIn: (v: string) => string;
	maskOut: (v: string) => string;
}
