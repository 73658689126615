import * as React from "react";

import { fontSizes, fontWeightsType, lineHeightsType, styled } from "../../utils/theme";

interface Props {
	fontWeight?: fontWeightsType;
	color?: string;
	style?: React.CSSProperties;
}

type ITypographyProps = React.PropsWithChildren<Props>;

interface StyleArgs {
	fontWeight: fontWeightsType;
	lineHeight: lineHeightsType;
	fontSize: fontSizes;
}

const Typograpy = styled.div<StyleArgs>`
	${({ theme }) => theme.font.lato}
	font-style: normal;
	color: ${p => p.color ?? p.theme.colors.others.dark.darkest};
	${p => p.theme.fn.fontWeight(p.fontWeight)}
	${p => p.theme.fn.lineHeight(p.lineHeight)}
    ${p => p.theme.fn.fontSize(p.fontSize)}
`;

export const T1 = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="xxxl"
		lineHeight="far"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
export const T2 = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="xxl"
		lineHeight="far"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
export const T3 = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="xl"
		lineHeight="far"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
export const T4 = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="lg"
		lineHeight="far"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
export const T5 = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="md"
		lineHeight="far"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
export const T6 = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="sm"
		lineHeight="far"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
export const TP = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="xs"
		lineHeight="compact"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
export const TM = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="xxs"
		lineHeight="compact"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
export const TC = (props: ITypographyProps) => (
	<Typograpy
		fontWeight={props.fontWeight ?? "regular"}
		color={props.color}
		fontSize="xxxs"
		lineHeight="compact"
		style={props.style}
	>
		{props.children}
	</Typograpy>
);
