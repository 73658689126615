import { borderRadiusType, borderWidthsType, spacingSize, styled } from "../../utils/theme";

interface ContainerProps {
	direction: "row" | "column";
	spacing: spacingSize;
}

interface ColorBlockProps {
	background: string;
	blockWidth: number;
	blockHeight: number;
	borderRadius: borderRadiusType;
	isActive: boolean;
	activeBorderWidth: borderWidthsType;
	activeBorderColor: string;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: ${props => props.direction};
	align-items: center;
	justify-content: center;

	gap: ${props => props.theme.spacing[props.spacing]};
`;

export const ColorBlock = styled.span<ColorBlockProps>`
	width: ${props => `${props.blockWidth}px`};
	height: ${props => `${props.blockHeight}px`};

	background: ${props => props.background};

	${props => props.theme.fn.borderRadius(props.borderRadius)};
	${props => props.isActive && props.theme.fn.borderWidth(props.activeBorderWidth)};
	border-color: ${props => (props.isActive ? props.activeBorderColor : "none")};

	cursor: ${props => (props.isActive ? "default" : "pointer")};

	transition: filter 0.2s;

	:hover {
		filter: brightness(0.9);
	}
`;
