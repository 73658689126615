import { keyframes } from "styled-components";

import { styled } from "../../utils/theme";

export const LoadingWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	${({ theme }) => theme.fn.paddingSquare("nano")}
`;

export const RotateAnimation = keyframes`  
	0% {
    	transform: rotate(0deg);
  	}
  	100% {
    	transform: rotate(360deg);
  	}
`;

export const LoadingCircle = styled.span`
	width: ${({ theme }) => theme.spacing.md};
	height: ${({ theme }) => theme.spacing.md};
	border: transparent solid 5px;
	border-bottom-color: ${({ theme }) => theme.colors.brand.primary.medium};
	border-radius: 50%;
	display: inline-block;
	animation: ${RotateAnimation} 1s linear infinite;
`;
