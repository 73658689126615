import { random } from "lodash-es";

export async function promiseMock<T>(
	resolveValue: T,
	duration?: number,
	successRate?: number,
): Promise<T> {
	const resolveRate = successRate ?? 100;
	const pendindTime = duration ?? 1000;

	return new Promise((resolve, reject) => {
		window.setTimeout(() => {
			const shouldSuceed = random(0, 100, false) <= resolveRate;

			if (shouldSuceed) {
				resolve(resolveValue);
			} else {
				reject({
					type: "promiseMockError",
					message: "Hello, i'm a promise and i'm failing!",
				});
			}
		}, pendindTime);
	});
}
