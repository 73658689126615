import { css } from "styled-components";

import { PositionType, IMessageSizes } from ".";
import { styled } from "../../utils/theme";

interface IMessageProps {
	position: PositionType;
	messageSize: IMessageSizes;
}

function getTooltipPosition(position: PositionType, messageSize: IMessageSizes) {
	switch (position) {
		case "top":
			return css`
				bottom: 150%;
				left: 10%;
				margin-left: ${() => `-${messageSize.width / 2}px`};
			`;
		case "bottom":
			return css`
				top: 150%;
				left: 10%;
				margin-left: ${() => `-${messageSize.width / 2}px`};
			`;
		case "left":
			return css`
				top: ${() => `-${messageSize.height / 3.3}px`};
				right: 170%;
			`;
		case "right":
			return css`
				top: ${() => `-${messageSize.height / 3.3}px`};
				left: 170%;
			`;
		default:
			return css``;
	}
}

function handleBallonMessagePosition(position: PositionType, messageSize: IMessageSizes) {
	switch (position) {
		case "right":
			return css`
				top: 50%;
				right: 100%;
				border-color: transparent black transparent transparent;
			`;
		case "left":
			return css`
				top: 50%;
				left: 100%;
				border-color: transparent transparent transparent black;
			`;
		case "top":
			return css`
				left: 49%;
				top: ${() => (messageSize.height > 40 ? "110%" : "115%")};
				border-color: black transparent transparent transparent;
			`;
		case "bottom":
			return css`
				left: 49%;
				bottom: 100%;
				border-color: transparent transparent black transparent;
			`;
		default:
			return "";
	}
}

export const ToolTipWrapper = styled.div<IMessageProps>`
	position: relative;
	height: 16px;
	width: 16px;

	.tooltipText {
		${({ theme }) => theme.font.lato}
		visibility: hidden;
		background-color: black;
		max-width: 280px;
		width: max-content;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 8px;
		font-size: 14px;

		position: absolute;
		z-index: 9999;
		${({ position, messageSize }) => getTooltipPosition(position, messageSize)}

		&::after {
			content: " ";
			position: absolute;
			margin-top: -5px;
			border-width: 5px;
			border-style: solid;

			${({ position, messageSize }) => handleBallonMessagePosition(position, messageSize)}
		}
	}

	&:hover .tooltipText {
		visibility: visible;
	}
`;
