import React, { useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";

import { treatStringForSearch } from "../../helpers/treatStringForSearch";
import { useOutsideClick } from "../../helpers/useOutsideClick";
import { IOptions } from "../../utils/types";
import { Props as BasicInputProps } from "../Inputs/basic";
import { CustomInput } from "../Inputs/custom";
import { ListOptions } from "../ListOptions";
import { Container } from "./styles";

interface ISelectProps extends Omit<BasicInputProps, "onChange"> {
	options: IOptions[];
	onChange?: (value: any) => void;
	isSearchable?: boolean;
}

export function Select({ options, onChange, value, isSearchable, ...props }: ISelectProps) {
	const [searchValue, setSearchValue] = useState(
		options.find(option => option.value === value)?.label ?? "",
	);
	const [showList, setShowList] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useOutsideClick(ref, () => {
		setShowList(false);
	});

	const optionsFiltered = options.filter(option =>
		treatStringForSearch(option.label).includes(treatStringForSearch(searchValue)),
	);

	const handledOptions = isSearchable ? optionsFiltered : options;

	return (
		<Container ref={ref}>
			<CustomInput
				value={
					isSearchable
						? searchValue
						: options.find(option => option.value === value)?.label ?? value
				}
				suffix={<FaAngleDown color="#A3A4A4" size={14} />}
				onClick={() => setShowList(currValue => !currValue)}
				onChange={valueInput => setSearchValue(valueInput)}
				readOnly={!isSearchable}
				{...props}
			/>

			<ListOptions
				show={showList}
				handleOptions={handledOptions}
				onClose={() => setShowList(false)}
				onChange={onChange}
				onSearch={setSearchValue}
			/>
		</Container>
	);
}
