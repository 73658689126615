import { DefaultTheme, css } from "styled-components";

import { DefaultButton, IDefaultButtonProps } from "../../../components/Button";
import { styled, theme } from "../../../utils/theme";

export interface IButtonProps
	extends Omit<
		IDefaultButtonProps,
		"backgroundColor" | "textColor" | "hoverColor" | "loadingColor"
	> {
	backgroundColor?: "green" | "dark green" | "blue" | "white";
	textColor?: string;
	hoverColor?: string;
	loadingColor?: string;
	fontWeight?: "bold" | "regular";
	borderRadius?: boolean;
	border?: boolean;
	justify?: "center" | "space-between";
}

let bgColor = "";

function handleTranslateColor(input: string) {
	switch (input) {
		case "green":
			return theme.colors.b2c.green.regular;
		case "dark green":
			return theme.colors.b2c.green.dark;
		case "blue":
			return theme.colors.b2c.blue;
		case "white":
			return "#f5f5f5";
		default:
			return undefined;
	}
}

export const Button = styled(DefaultButton)<IButtonProps>`
	justify-content: ${({ justify }) => justify || "center"};

	${({ theme }) => theme.font.ubuntu};
	font-weight: ${({ fontWeight }) => fontWeight || "500"};
	border-radius: ${({ borderRadius }) => (borderRadius ? "10px" : "0px")};
	background-color: ${({ backgroundColor, theme }) => {
		const input = handleTranslateColor(backgroundColor);
		const defaultBg = theme.colors.b2c.green.regular;

		if (!input) {
			bgColor = defaultBg;
			return bgColor;
		}

		return input;
	}};
	color: ${({ textColor, theme }) =>
		handleTranslateColor(textColor) || theme.colors.others.dark.darkest};

	border: ${({ textColor, border }) => (border ? `1px solid ${textColor}` : "none")};

	&:hover {
		background-color: ${({ hoverColor, backgroundColor }) =>
			handleTranslateColor(hoverColor) || backgroundColor};
		border: none;
		filter: brightness(0.8);
	}

	${({ isLoading }) =>
		isLoading &&
		css<DefaultTheme>`
			cursor: not-allowed;
			opacity: ${({ theme }) => theme.opacityLevels.intense};

			&:hover {
				background-color: ${bgColor};
				border: none;
			}
		`};
`;
