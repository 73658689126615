import React, { PropsWithChildren } from "react";

import { styled, theme } from "../../utils/theme";
import { TP, T4 } from "../Typography";

const CardWrapper = styled.div<{ flat?: boolean }>`
	padding: 16px;
	border-radius: 16px;
	width: 100%;
	background: #ffffff;

	${({ flat }) => {
		if (flat) {
			return "border: 2px solid #8080802b;";
		}

		return "box-shadow: 0px 8px 24px rgba(34, 34, 34, 0.16);";
	}}
`;

interface CardProps {
	className?: string;
	flat?: boolean;
}

export function Card(props: PropsWithChildren<CardProps>) {
	const { children, ...restOfProps } = props;

	return <CardWrapper {...restOfProps}>{children}</CardWrapper>;
}

const Info = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	div:not(:first-child) {
		margin-top: 8px;
	}
`;

interface CardWithIndicatorsProps extends CardProps {
	label: string;
	content: string;
	contentColor?: string;
}

export function CardWithIndicators(props: CardWithIndicatorsProps) {
	return (
		<Card>
			<Info>
				{props.label ? (
					<TP fontWeight="bold" color={theme.colors.others.dark.medium}>
						{props.label}
					</TP>
				) : null}
				{props.content ? (
					<T4 fontWeight="bold" color={props.contentColor ?? theme.colors.brand.primary.medium}>
						{props.content}
					</T4>
				) : null}
			</Info>
		</Card>
	);
}
